
import React, { useState } from 'react';
import './OrderServices.css';
import Services from '../UserOrderService/UserOrderService';
import { useNavigate } from 'react-router-dom';

const FoodService = require('./../../Images/FoodDelivery.png');
const pickndropService = require('./../../Images/PickNdrop.png');
const OrganicProductService = require('./../../Images/organicProduct.png');
const CakeDeliverService = require("./../../Images/carasoul3.webp");

const OrderServices = () => {
  const [selectedService, setSelectedService] = useState(null);
  const navigate = useNavigate();

  const handleServiceClick = (serviceIndex, path) => {
    if (path) {
      navigate(path);
    } else {
      setSelectedService(selectedService === serviceIndex ? null : serviceIndex);
    }
  };

  return (
    <div className='oM-home-conatiner'>
      <div className='oM-home-services'>
        <div className='oM-TopConatiner'>
          <div className='oM-Food-Service'>
            <Services
              heading="Food Delivery"
              img={FoodService}
              color="#ffd32c"
              phoneNumber="7975443090"
              whatsappMessage="Hello, I am interested in your Food Delivery service"
              isSpecialService={true}
              onClick={() => handleServiceClick(0)}
              showPopup={selectedService === 0}
            />
          </div>
          <div className='oM-PND-Service'>
            <Services
              heading="Pick And Drop"
              img={pickndropService}
              color="#6fcce3"
              path="/pick-and-drop"
              onClick={() => handleServiceClick(1, "/pick-and-drop")}
            />
          </div>
        </div>
        <div className='oM-lowerConatiner'>
          <div className='oM-Organic-Service'>
            <Services
              heading="Organic Products"
              img={OrganicProductService}
              color="#affc97"
              phoneNumber="7975443090"
              whatsappMessage="Hello , I am interested in Ordering Organic Product From your Service Please Share the item List which you are delivering"
              isSpecialService={true}
              onClick={() => handleServiceClick(2)}
              showPopup={selectedService === 2}
            />
          </div>
          <div className='oM-CakeD-Service'>
            <Services
              heading="Cake Delivery"
              img={CakeDeliverService}
              color="#f786d3"
              phoneNumber="7975443090"
              whatsappMessage="Hello , I am interested in your cake delivery Service please let me know the procedure "
              isSpecialService={true}
              onClick={() => handleServiceClick(3)}
              showPopup={selectedService === 3}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderServices;

